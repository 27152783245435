.side-bar {
  background-color: $side-bar--background-color;
  border-left: 1px solid $side-bar--border-color;
  border-right: 1px solid $side-bar--border-color;
  height: 100vh;
  position: fixed;
  padding-left: 3rem;
  width: 24.3%;
  margin: 0 !important;

  @include breakpoint(small only) {
    width: 20rem;
  }

  .logo {
    @include breakpoint(small only) {
      margin-top: unset;
      display: block;
    }
    max-width: 60%;
    min-width: 14em;
  }

  .dropdown-menu {
    background-color: $white;
    border: rgba($black, 0.17) 1px solid;
    border-radius: 10px;
    color: $black;
    font-size: 16px;
    cursor: pointer;
    height: 70px;
    margin-top: 2rem;
    margin-bottom: auto;
    margin-bottom: 0px;
    width: 100%;
    outline: 0px;
    font-weight: 600;

    div {
      padding-left: .5px;
    }
  }

  .hover {
    border-radius: unset;
    border-bottom: unset;
    border-top-left-radius:10px;
    border-top-right-radius: 10px;
  }

  .dropdown-pane {
    background-color: $white;
    position: absolute;
    right: 15px;
    left: 15px;
    width: auto;
    border-radius: 0px;
    border: unset;
    padding: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;


    a {
      line-height: 42px;
      border:$medium-gray 1px solid;
      background-color: $white;
      width: -moz-available;
      display: block;
      color: $black;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      span {
        line-height: normal;
      }
    }
  }

  .menu-header {
    margin-left: 10px;

    a {
      background: none;
      color: #000;
      margin: 5px 0;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }

    h5 {
      margin-top: 10px;
    }
  }


  .menu {
    .nolinkmenuitem {
      display: block;
      padding: 2em 1rem 0.7rem;
      line-height: 1;
      font-style: italic;
    }

    a, .nolinkmenuitem {
      background: none;
      color: $black;
      margin: 5px 0;
      opacity: 0.4;
    }

    a {
      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }

  .profile {
    border-top: 2px solid $side-bar--border-color;
    margin-top: auto;
    padding-top: 25px;
    padding-bottom: 15px;

    display: flex;
    justify-content: space-between;
  }

  & > div {
    padding: 0 15px;
  }

  .canvas-toggler-container {
    display: flex;
    justify-content: right;
    display: none;

    @media only screen and (max-width: 640px) {
      display: flex;
    }

    .canvas-off {
      border-radius: unset;
      padding: 1rem 1rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.canvas-header {
  background-color: $side-bar--background-color;
  border-bottom: 1px solid $side-bar--border-color;
  margin: 0 !important;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 3rem;
  display: none;
  z-index: 10;

  @media only screen and (max-width: 640px) {
    display: flex;
    position: fixed;
  }

  .canvas-toggler {
    border-radius: unset;
    height: 3rem;
    width: 20%;
  }

  .canvas-logo-container {
    height: 3rem;
    justify-content: center;
    display: flex;
  }

  .canvas-logo {
    padding-top: 0.3rem;
    height: 2.4rem;
  }
}
