html, body {
  margin: 0;
  width: 100%;
  min-height: 100%;
}

.cloudy-background {
  overflow-x: hidden;
  background: url('../images/background.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a.arrow-link {
  &::after {
    content: ' >';
  }
}

.space {
  margin: 0 auto;
  padding: rem-calc(60) 0;
}

.large-space {
  @extend .space;
  padding: rem-calc(100) 0;
}

.micro-space {
  @extend .space;
  padding: rem-calc(3) 0;
}

.mini-space {
  @extend .space;
  padding: rem-calc(15) 0;
}

.mini-space-top {
  padding-top: 0.6rem;
}

.small-space {
  @extend .space;
  padding: rem-calc(25) 0;
}

small,
.small {
  font-size: 13px;
  line-height: 18px;
}

small.light,
.small.light {
  color: $dark-gray;
}

.text-subtle {
  opacity: 0.6;
  font-size: 0.9rem;
}

.project-info {
  margin-top: 54px;
  position: relative;

  @supports (position: sticky) {
    margin-top: 0;
  }
}

.project-info-padded {
  padding: 24px 20px 20px;
}

.hidden {
  display: none;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;

  &--green {
    @extend .dot;
    background-color: #008000;
  }
}

.circle {
  border-radius: 50%;
}

.webgl-legend {
  position: absolute;
  bottom: 0px;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding: 0.5rem;

  .white {
    color: $white;
  }

  ul {
    list-style: none;
    margin: 0px;
  }

  div.indicator {
    height: 1rem;
    width: 1rem;
    background-color: #555;
    display: inline-block;
    margin-right: 10px;
  }

  div.indicator-polarity {
    background-color: #4990E9;
  }
}

.webgl-canvas {
  width: 100%;
  height: calc(100vh - 54px);
  display: block;
  background-color: #cacaca;
  overflow: hidden;
}

.logo-list {
  @extend .grid-x;
  margin-bottom: 2rem;
  width: 100%;

  .logos {
    width: inherit;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;

    margin-top: 2rem;

    img {
      height: 3rem;
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}

i.icon-success {
  color: map-get($foundation-palette, success)
}

i.icon-warn {
  color: map-get($foundation-palette, warning)
}

i.icon-error {
  color: map-get($foundation-palette, danger)
}

i.icon-info {
  color: $dark-gray;
}

// These are styles used by Mastodon and required as we embed the aisler.social feed
span.invisible {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
}

span.ellipsis {
  &::after {
    content: "…";
  }
}
