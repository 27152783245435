.fritzing-logo {
  width: 400px;
  margin-bottom: 2rem;
}

.fritzing-pcb {
  padding-top: 12px;
}

.target-logo {
  width: 250px;
  margin-left: -15px;
}

.eagle-logo {
  width: 200px;
  margin-bottom: 2rem;
}

.kicad-logo {
  width: 200px;
  margin-bottom: 2rem;
}