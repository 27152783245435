/* repository renderings */
.repositories-item {
  margin: 20px 0;
  height: 140px;
  position: relative;
  overflow-wrap: break-word;
  word-break: break-all;
  width: 100%;
  color: $primary-color;
}
.repositories-item:hover {
  cursor: pointer;
}
.repositories-project-count {
  position: absolute;
  display: block;
  width: 100%;
  height: 20px;
  bottom: 20px;
  left: 0px;
  padding: 20px;
  text-align: right;
  font-size: 80%;
}
.icon-black {
  color: black;
  padding-right: 8px;
}
.margin-top-left {
  margin-top: 25px;
  margin-left: 25px;

  @include breakpoint(small only) {
    margin-top: 15px;
    margin-left: 15px;
  }
}

.clickable-card {
  transition: 0.2s;
}
.clickable-card:hover{
  background: $light-gray;
}
