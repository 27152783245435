@keyframes arrowAnim {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-10%);
  }
}

.project-header {
  position: fixed;
  z-index: 10;
  background: $white;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0px;
  height: 54px;
  padding: 0 1rem 0 1rem;

  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  ul.menu {
    margin: 0;

    .checkout {
      margin-left: auto;
      margin-right: 10px;

      @media screen and (max-width: 1050px) {
        margin: unset;
        font-size: 12px;
        margin: 0;
      }
    }

    &, li {
      height: 100%;
    }

    @include breakpoint(small only) {
      padding: 0 10px;
      justify-content: space-between;
    }

    li {
      display: flex;
      align-items: center;
      transform: translateY(1px); // puts the active border on the menu line


      @media screen and (max-width: 1050px) {
        font-size: 12px;
        margin: 0;
      }

      &.active {
        border-bottom: 2px solid $primary-color;

        a {
          background: none;
          color: $black;
        }
      }

      &.inactive {
        color: $medium-gray;
        padding: 0.7rem 1rem;
      }

      a {
        color: $dark-gray;
        transition: 0.3s ease-in;

        @include breakpoint(small only) {
          padding: 0.7rem;
        }
      }

      &:hover a,
      &.back-link a {
        @include breakpoint(small only) {
          display: none;
        }
      }

      &.back-link {
        max-width: 14rem;
        min-width: 24px;
        color: $black;

        @include breakpoint(small only) {
          width: 30px;
          padding: 0;
        }

        a {
          white-space: nowrap;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
