.top-bar {
  img.logo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 40px;
  }

  a {
    color: $black;
    font-weight: 500;

    &.button {
      font-weight: 700;
    }
  }

  &-right {
    .menu {
      .button {
        margin-left: 0.5rem;
      }
    }
  }
}

.title-bar {
  .menu-icon {
    margin-right: 2rem;
  }

  // Default
  .logo {
    max-height: 2rem;
  }

  .button {
    margin-bottom: 0;
  }
}
