.service-hero {
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  background: $white;
  border-radius: 16px;

  .title {
    @extend .cell;
    @extend .large-4;
    @extend .large-offset-1;
    @extend .medium-8;
    @extend .medium-offset-1;
    @extend .small-10;
    @extend .small-offset-1;

    h1 {
      line-height: 1;
    }
  }

  .teaser {
    @extend .cell;
    @extend .large-7;
    @extend .large-offset-1;
    @extend .medium-10;
    @extend .medium-offset-1;
    @extend .small-10;
    @extend .small-offset-1;
  }
}

.service-description {
  @extend .grid-x;
  @extend .grid-padding-x;

  .description {
    @extend .cell;
    @extend .medium-offset-1;
    @extend .medium-10;

    p {
      margin-bottom: 1rem;
    }

    li {
      margin-bottom: 1rem;
    }
  }

  margin-bottom: 4rem;
}

.service-more {
  .more-header {
    @extend .cell;
    @extend .large-6;
    @extend .large-offset-3;
    @extend .small-12;
    @extend .small-offset-0;
    @extend .text-center;

    margin-bottom: 2rem;
  }

  .more-links {
    @extend .cell;
    @extend .text-center;

    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  margin-bottom: 4rem;
}
