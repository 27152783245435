.upload {
  .drop-area {
    @extend .grid-x;

    margin-bottom: 1rem;

    @include breakpoint(large) {
      margin-bottom: 3rem;
    }
  }

  .supported-applications {
    @extend .grid-x;

    font-weight: 500;

    @include breakpoint(large) {
      margin-bottom: 4rem;
    }

    @include breakpoint(small) {
      margin-bottom: 2rem;
    }

    .logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;

      margin-top: 2rem;

      img {
        height: 3rem;
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
  }
}
