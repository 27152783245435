.hero {
  @extend .grid-x;
  @extend .grid-margin-x;
  @extend .text-center;

  margin-top: 2rem;
  margin-bottom: 1.5rem;

  h1 {
    line-height: 1.2;
  }

  p {
    margin: 2rem 5%;

    @include breakpoint(large) {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}
