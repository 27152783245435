.project-cards {
  transform: translateY(-30px);
  padding-bottom: 25px;

  display: grid;
  grid-template-columns: none;
  grid-template-rows: auto;
  column-gap: 1rem;

  @supports (grid-template-rows: masonry) {
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Masonry_Layout for when it hopefully becomes available soon
    grid-template-rows: masonry;
  }

  justify-content: stretch;
  align-items: start;

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
  }

  &.single-col {
    grid-template-columns: none;
  }

  .project-card {
    //margin: 1%;
    break-inside: avoid;
  }
}

.project-card {
  background: $white;
  box-shadow: 1px 1px 40px 0 rgba($black, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: $white;

  align-self: start;

  h4 {
    font-size: 1.25rem;
    color: $black;
    letter-spacing: 0;
  }

  img.splash {
    position: relative;
    width: 100%;
  }

  &.emphasis {
    &--error {
      border-top-color: $red;
    }

    &--warning {
      border-top-color: $yellow;
    }

    &--success {
      border-top-color: $green;
    }

    &--highlight {
      border-top-color: $blue;
    }

    &--secondary {
      border-top-color: $medium-gray;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    align-self: flex-end;
  }

  .action {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 0.9rem;
    text-align: right;
    line-height: 0.9rem;

    &.primary {
      color: $orange;
    }

    &.secondary {
      color: $dark-gray;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &.expand {
    height: 100%;
  }
}
