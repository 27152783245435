.text {
  &.primary {
    color: map-get($foundation-palette, primary);
  }

  &.secondary {
    color: map-get($foundation-palette, secondary);
  }

  &.success {
    color: map-get($foundation-palette, success);
  }

  &.warning {
    color: map-get($foundation-palette, warning);
  }

  &.danger {
    color: map-get($foundation-palette, danger);
  }
}
