.full-width-bar {
  height: 7rem;

  .bar {
    position: absolute;
    height: 3.8rem;
    background: linear-gradient(90deg, #ffa011 0%, #ff5f11 100%);
    width: 200%;
    left: -50%;
    transform: rotate(-3deg);
    z-index: 100;
  }

  .shadow {
    position: absolute;
    height: 4.3rem;
    background: linear-gradient(0deg, #ffa01199 0%, #ff5f1199 100%);
    width: 200%;
    left: -50%;
    transform: rotate(-3.3deg) translate(0, -0.25rem);
    z-index: 50;
  }
}
