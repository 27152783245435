.button {
  color: $white;

  &:hover, &:focus {
    color: $white;
  }

  &.primary:not(.hollow) {
    color: $white;
    transition: all 300ms;

    & {
      background: $orange-gradient;
      opacity: 1;
      color: $white;
    }

    &.disabled, &[disabled],
    &.disabled:hover, &[disabled]:hover,
    &.disabled:focus, &[disabled]:focus {
      background: $orange-gradient-disabled;
      color: #0007;
      opacity: 1;
    }

    &:not(.disabled):not([disabled]):hover,
    &:not(.disabled):not([disabled]):focus {
      opacity: 0.8;
      color: $white;
    }
  }

  &.secondary {
    &.hollow {
      color: $black;
      border-color: scale-color(get-color(secondary), $lightness: -20%);

      &:hover {
        color: $black;
      }
    }

    &.active {
      background-color: scale-color(get-color(secondary), $lightness: -30%);
    }
  }

  &.image {
    $increase: 8px;
    padding:  get-side($button-padding, top) - -zf-bp-to-em($increase)
              get-side($button-padding, right) - -zf-bp-to-em($increase)
              get-side($button-padding, bottom) - -zf-bp-to-em($increase)
              get-side($button-padding, left) - -zf-bp-to-em($increase);

    img {
      height: map-get($button-sizes, default) + rem-calc($increase * 1.6);
    }
  }
}


.button-onboarding {
  @include button(false, $body-background, $body-background, $black, solid);
  border: 3px solid #E3E3E3;

  &.selected {
    border: 3px solid $red;
  }

  img {
    height: 6rem;
  }

}
