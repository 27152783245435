.button-group {
  @each $name, $color in $foundation-palette {
    .button {
      &.#{$name} {
        border: 1px solid scale-color($color, $lightness: -20%);

        &.selected {
          background:  scale-color($color, $lightness: -20%);
        }
      }
    }
  }
}
