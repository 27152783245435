.product-hero {
  padding-bottom: 2rem;

  background: $white;
  border-radius: 16px;

  /*
   * This move the icon about the offset out of the hero card but maintains the used space as margin.
   */
  $icon-offset: 0.65;

  @each $size, $headers in $header-styles {
    @include breakpoint($size) {
      margin-top: 2rem + $icon-offset * 2 * rem-calc(map-get(map-get($headers, 'h1'), 'font-size'));
    }
  }

  .icon {
    @each $size, $headers in $header-styles {
      @include breakpoint($size) {
        height: 2 * rem-calc(map-get(map-get($headers, 'h1'), 'font-size'));
        margin-top: - $icon-offset * 2 * rem-calc(map-get(map-get($headers, 'h1'), 'font-size'));
      }
    }
  }

  .title {
    @extend .cell;
    @extend .large-8;
    @extend .large-offset-1;
    @extend .medium-8;
    @extend .medium-offset-1;
    @extend .small-10;
    @extend .small-offset-1;

    h1 {
      line-height: 1;
    }
  }

  .teaser {
    @extend .cell;
    @extend .large-7;
    @extend .large-offset-1;
    @extend .medium-10;
    @extend .medium-offset-1;
    @extend .small-10;
    @extend .small-offset-1;
  }
}

.product-features {
  .feature {
    @extend .grid-x;
    @extend .grid-padding-x;

    flex-direction: row;

    &:nth-child(2n) {
      @include breakpoint(large) {
        flex-direction: row;
      }

      @include breakpoint(small) {
        flex-direction: column-reverse;
      }
    }

    .header {
      @extend .cell;
      @extend .large-4;
    }

    .content {
      @extend .cell;
      @extend .large-6;
    }

    .left {
      @extend .text-left;
      @extend .large-offset-1;
    }

    .right {
      @extend .text-right;
    }

    margin-bottom: 2rem;
  }

  margin-bottom: 4rem;
}

.product-more {
  .more-header {
    @extend .cell;
    @extend .large-6;
    @extend .large-offset-3;
    @extend .small-12;
    @extend .small-offset-0;
    @extend .text-center;

    margin-bottom: 2rem;
  }

  .more-links {
    @extend .cell;
    @extend .text-center;

    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;

    @include breakpoint(small only) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  margin-bottom: 4rem;
}
