.card {
  background: $white;
  box-shadow: 1px 1px 140px 0 rgba(0, 0, 0, 0.1);
  border-radius: $global-radius;
  display: block;
  margin: auto;
  padding: 25px;
  box-sizing: border-box;

  .icon {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  img.icon {
    width: 3.5em;
  }

  @include breakpoint(small only) {
    padding: 15px;
    margin: 10px 0;
  }

  &__nodge-top {
    transform: translateY(-20px);
  }

  .button :not(.input-group-button) :not(.BeatLoader)> * {
    height: 3rem;
  }
}
