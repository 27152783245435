.notes-bar {
  height: 100%;
  width: inherit;
  position: absolute;
  background-color: $body-background;
  overflow: auto;
  border-left-style: solid ;
  border-left-width: 1px;
  border-color: #b2b2b2;

  .loader {
    margin-top: 10rem;
  }
}
