.image-preview {
  margin: 0 auto;
  padding: rem-calc(40) 0;
  min-height: 150px;

  background: $medium-gray;

  &__big {
    height: calc(100vh - 150px - 53px);

    @include breakpoint(small only) {
      height: calc(100vh - 170px - 53px);
    }
  }

  &__fs {
    height: calc(100vh - 53px);
  }

  .column {
    height: 100%;
  }

  &--download {
    position: absolute;
    left: 4rem;
    top: 2%;
    display: inline-block;
    width: 25px;
    height: 25px;
    background: $white center/8px image-url('icon__download.svg') no-repeat;
    color: $black;
    cursor: pointer;
    border-radius: 180px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  &--sharing {
    position: absolute;
    left: 1.5rem;
    top: 2%;
    display: inline-block;
    width: 25px;
    height: 25px;
    background: $white center/10px image-url('icon__sharing.svg') no-repeat;
    color: $black;
    cursor: pointer;
    border-radius: 180px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  img {
    position: relative;
    max-height: 14rem;
    max-width: 18rem;
    width: auto;
    height: auto;
  }

  .loading {
    margin-top: 10rem;
  }

  button.revision-btn {
    margin-top: 0.2rem;
    span {
      padding-left: 6px;
    }

    position: absolute;
    right: 2%;
    top: 2%;
    display: inline-block;
    color: #000;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .revisions {
    display: block;
    position: absolute;
    background-color: $light-gray;
    min-width: 250px;
    border-radius: 10px;
    border-top-right-radius: 0px;
    box-shadow: 0px 8px 16px 0px rgba($black,0.2);
    margin: auto;
    padding-right: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
    z-index: 1;
    top: 65px;
    right: 2%;

    a {
      font-weight: 900;
      font-size: 16px;
      display: inline-block;
      margin: 0;
      color: $black;
      opacity: 0.4;
      width: 5.8rem;

      &:hover {
        opacity: 1.0;
      }

      &.active {
        opacity: 1.0;
      }
    }
    .details {
      display: inline-block;
      font-size:13px;
      color: $dark-gray;
      padding-right: 90px;
    }
    .grid-x {
      height: 40px;
    }

    .status {
      position: absolute;
      right: 10px;
      display: inline-block;
      margin-left: 10px;
      height: 15px;
    }

    .status > * {
      width: 15px;
      height: 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      margin-left: 20px;
    }

    .status .uploaded {
      background-image: image-url('icon__uploaded__singular.svg');
    }

    .status .manufactured {
      background-image: image-url('icon__manufacturing__singular.svg');
    }

    .status .shipped {
      background-image: image-url('icon__shipped__singular.svg');
    }
  }

}
