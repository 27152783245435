.hoverable {
  position: relative;
  display: inline-block;

  .tooltip {
    visibility: hidden;
    opacity: 0;
    background-color: $black;
    color: #fff;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    z-index: 11;
    position: absolute;

    width: 250px;

    text-transform: none;
    font-size: 1rem;
    font-weight: $global-weight-normal;
    font-family: $body-font-family;

    transition: visibility 250ms ease-out 0s, opacity 250ms ease-out 0s;

    // Generic arrow style
    &::after {
      content: " ";
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }

    // Width has to be fixed, so position tooltips on top
    &.left {
      top: -5px;
      right: calc(100% + 10px);

      &::after {
        top: 0.5rem;
        left: 100%;
        border-color: transparent transparent transparent $black;
      }
    }

    &.right {
      top: -5px;
      left: calc(100% + 10px);

      &::after {
        top: 0.5rem;
        right: 100%;
        border-color: transparent $black transparent transparent;
      }
    }

    // Width is fixed, so center tooltips
    &.top {
      bottom: calc(100% + 10px);
      left: 50%;
      margin-left: -125px;

      &::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-color: $black transparent transparent transparent;
      }
    }

    // Width is fixed, so center tooltips
    &.bottom {
      top: calc(100% + 10px);
      left: 50%;
      margin-left: -125px;

      &::after {
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-color: transparent transparent $black transparent;
      }
    }
  }

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: visibility 100ms ease-in 0s, opacity 100ms ease-in 0s;
  }
}
