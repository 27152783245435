.dropdown-item-space {
  margin-bottom: 0.35em;
}

.dropdown-spacing {
  padding: rem-calc(10) 1rem;
  width: auto;
}

.dropdown-button {
  background: transparent !important;
  width: fit-content;
  margin-right: 18px;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 6px;
  transition: 0.2s;

  @include breakpoint(small only) {
    margin-right: 8px;
  }
}

.dropdown-button:hover {
  background-color: $medium-gray !important;
  cursor: pointer;
}

.dropdown-wrapper {
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}

.dropdown-option:not(:last-child) {
  opacity: 0.8;
  width: max-content;
  margin-bottom: 0.5rem
}

.dropdown-option:hover {
  opacity: 1;
}
