footer{
  @extend .grid-container;
  @extend .full;

  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  background: $white-gradient;

  @include breakpoint(large) {
    margin-top: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .logo {
    width: 90%;
  }

  .mini-logo {
    padding: 0.5rem;
    width: 49%;
  }

  .section {
    margin-bottom: 1rem;
  }

  .link-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: $black;
    }

    a:hover {
      color: map-get($foundation-palette, primary);
    }
  }

  .footnote {
    font-size: 0.7rem;
    gap: 0.25rem;

    .link-list {
      flex-direction: row;
    }
  }
}
